import ControlList from "../ControlList";
import moment from "moment";

/**
 * Klasa reprezentująca Remanent
 * @extends ControlList
 */
class RemanentControlList extends ControlList {

    getDatesForList() {
        return {
            start: moment().startOf("day").subtract(35, "days"),
            end: moment()
        }
    }

}

export default RemanentControlList